import React, { memo } from 'react'
import { formatDate } from '../../utils/functions'
import usePost from '../../hooks/usePost'
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { Button, message } from 'antd';
import { setAllTrips } from '../../store/features/trip/tripSlice';

const TrashedTripCard = ({ trip }) => {

    const { post, loading } = usePost();
    const dispatch = useDispatch();

    const restoreTrip = async () => {
        try {
            await post("/updatetrip.php", { ...trip, isdeleted: 0, isactive: 1 });
            // after deleting trip getting latest trips 
            const latestTrips = await axios.get(`${process.env.REACT_APP_BASE_URL}/getalltrips.php/?aid=1&t=${new Date().getTime()}`);
            dispatch(setAllTrips(latestTrips.data));
            message.success("trip restores successfully");
        } catch (error) {
            console.log(error);
            message.error("Failed to restore trip");
        }
    }

    return (
        <div className="bg-[#ffe9e9] border-2 shadow-sm border-[#E9E9E9] mt-4 rounded-lg overflow-hidden">
            <div className="px-4 py-2 hover:bg-[#ffdbdb] duration-300">
                <div className="flex items-center justify-between">
                    <h2 className="text-lg font-semibold text-gray-800">{trip.title}</h2>
                    <h2 className="text-[16px] font-semibold text-gray-800">Status {+trip.isactive === 1 ? "Active" : "Non Active"}</h2>
                </div>
                <div className="flex items-center justify-between mt-2">
                    <div className="flex items-center text-sm text-gray-600">
                        <span className="mr-2">Start Date:</span>
                        <span className="font-medium">{formatDate(trip.startdate)}</span>
                    </div>
                    <div className="flex items-center text-sm text-gray-600">
                        <span className="mr-2">End Date:</span>
                        <span className="font-medium">{formatDate(trip.enddate)}</span>
                    </div>
                </div>
                <div className="flex items-center justify-end mt-2  border-[#f3f3f3] py-2">
                    <Button
                        loading={loading}
                        disabled={loading}
                        onClick={restoreTrip}
                        className='text-[14px] sm:text-[18px] rounded-md leading-normal shadow-lg hover:bg-themeRed  text-white bg-themeBlue h-auto inline font-medium px-3 py-1 duration-200'
                    >
                        Restore
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default memo(TrashedTripCard);